import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";
import { FinalReport, TechnicanTicketReport } from "models/reports";

class ReportsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Post(id: any, data: any): Promise<ApiResponse<any>> {
    return this.put(`/api/tickets/${id}/reports`, data);
  }
  public async Fetch(id: any): Promise<ApiResponse<any>> {
    return this.get(
      `/api/tickets/${id}/reports?items_per_page=200`
    );
  }
  public async FetchPrevious(id: any): Promise<ApiResponse<any>> {
    return this.get(
      `/api/tickets/client_previous_report/${id}?items_per_page=200&index_type=list`
    );
  }
  public async FetchOld(id: any): Promise<ApiResponse<any>> {
    return this.get(
      `/api/tickets/${id}/old-reports?items_per_page=200&index_type=list`
    );
  }
  public async FetchSingle(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/tickets/reports/${id}`);
  }
  public async Delete(id: any): Promise<ApiResponse<any>> {
    return this.delete(`/api/tickets/reports/${id}`);
  }
  public async Updata(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/reports/${id}`, data);
  }
  public async AddReport(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/reports`, data);
  }
  public async addCheckin(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/check-in`, data);
  }
  public async addCheckout(
    ticketId: any,
    reportId: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${ticketId}/${reportId}/check-out`, data);
  }

  public async pauseReport(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/pauseOnReport/${id}`);
  }
  public async unPauseReport(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/pauseOffReport/${id}`);
  }
  public async UpdataFormal(id: any, data: any): Promise<ApiResponse<any>> {
    return this.put(`/api/tickets/${id}/formal-report`, data);
  }
  public async FetchFormal(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/tickets/${id}/formal-report`);
  }
  public async uploadFormalPDF(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/tickets/${id}/pdf-report`, data);
  }
  public async FetchReportsActivity(
    startDate: string,
    endDate: string,
    page: number,
    perPage: number
  ) {
    return this.get(
      `/api/get-activity-report?page=${page}&items_per_page=${perPage}&filter[startDate]=${startDate}&filter[endDate]=${endDate}`
    );
  }

  public async FetchTechniciansReports(
    page: number,
    perPage: number,
    id: number,
    userId: number
  ): Promise<ApiResponse<TechnicanTicketReport[]>> {
    return this.get(
      `/api/users/${userId}/tickets/${id}?page=${page}&items_per_page=${perPage}`
    );
  }

  public async FetchFinalReports(
    page: number,
    perPage: number,
    searchBy: any,
    search: any
  ): Promise<ApiResponse<FinalReport[]>> {
    return this.get(
      `/api/final-report?page=${page}&items_per_page=${perPage}${
        search !== "" ? `&filter[${searchBy}]=${search}` : ""
      }`
    );
  }
}

const reportsService = new ReportsService();

export const postReports = async (obj: any) => {
  const result: any = await reportsService.Post(obj.id, obj.reports);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchReports = async (id: any) => {
  if (id !== "0") {
    const result: any = await reportsService.Fetch(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchPreviousReports = async (id: any) => {
  if (id !== "0") {
    const result: any = await reportsService.FetchPrevious(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchOldReports = async (id: any, show: any) => {
  if (id !== "0" && show) {
    const result: any = await reportsService.FetchOld(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchFormalReport = async (id: any) => {
  if (parseInt(id) !== 0) {
    const result: any = await reportsService.FetchFormal(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchReport = async (id: any) => {
  if (id !== 0) {
    const result: any = await reportsService.FetchSingle(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const DeleteReportAsync = async (id: any) => {
  const result: any = await reportsService.Delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdateReportAsync = async (obj: any) => {
  if (obj.id !== 0) {
    const result: any = await reportsService.Updata(obj.id, obj.data);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const UpdateFormalReportAsync = async (obj: any) => {
  if (obj.id !== 0) {
    const result: any = await reportsService.UpdataFormal(obj.id, obj.data);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const AddReport = async (obj: any) => {
  const result: any = await reportsService.AddReport(obj.id, obj.data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const AddCheckinReport = async (obj: any) => {
  const result: any = await reportsService.addCheckin(obj.id, obj.data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const AddCheckoutReport = async (obj: any) => {
  const result: any = await reportsService.addCheckout(
    obj.ticketId,
    obj.reportId,
    obj.data
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const pauseReport = async (id: number) => {
  const result: any = await reportsService.pauseReport(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const unPauseReport = async (id: number) => {
  const result: any = await reportsService.unPauseReport(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const UploadFormalPDFAsync = async (obj: any) => {
  const result: any = await reportsService.uploadFormalPDF(obj.id, obj.data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const GetReportsActivity = async (
  startDate: string,
  endDate: string,
  page: number,
  perPage: number
) => {
  const result: any = await reportsService.FetchReportsActivity(
    startDate,
    endDate,
    page,
    perPage
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchTechniciansTicketsReports = async (
  page: number,
  perPage: number,
  id: number,
  userId: number
) => {
  const result: any = await reportsService.FetchTechniciansReports(
    page,
    perPage,
    id,
    userId
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const GetFinalReports = async (
  page: number,
  perPage: number,
  searchBy: any,
  search: any
) => {
  const result: any = await reportsService.FetchFinalReports(
    page,
    perPage,
    searchBy,
    search
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
